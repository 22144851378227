<template>
    <div class="admin-content">
        <h3 v-text="state.defaults.BoardName"></h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button type="button" class="btn-box" v-on:click="methods.getNotices">조회</button>
                    <button type="button" class="btn-box green" v-on:click="methods.moveDtls('N');">신규</button>
                </div>
            </div>
            <div>
                <div class="select-wrap">
                    <div class="select-box">
                        <select v-model="state.parameter.UseYn">
                            <option value="">전체</option>
                            <option value="Y">사용</option>
                            <option value="N">삭제</option>
                        </select>
                    </div>
                    <div class="select-box">
                        <select v-model="state.parameter.SearchType">
                            <option value="1">제목</option>
                            <option value="2">내용</option>
                            <option value="3">작성자</option>
                        </select>
                    </div>
                    <input type="text" v-model="state.parameter.SearchText" v-on:keyup.enter="methods.getNotices"
                        placeholder="검색어" class="input-text mr10" style="width:180px;" />
                    <div class="select-box">
                        <select v-model="state.parameter.CodeVal">
                            <option selected="selected" value="">전체선택</option>
                            <option v-for="code in state.codes" v-bind:value="code.CodeValue" v-bind:key="code"
                                v-text="code.CodeName" />
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="total-box">
            <p>Total <strong>{{ state.boardDatas.length }}</strong></p>
        </div>
        <DataTable
            class="p-datatable-sm"
            columnResizeMode="fit" 
            scrollHeight="495px"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            :value="state.boardDatas" 
            :paginator="true"
            :rowHover="true"
            :rows="notice.gridTopSize"
            :resizableColumns="true"
            @row-click="eventHandler.dataTable.onRowClick">
            <template #empty>
                데이터가 없습니다.
            </template>
            <template #loading>
                Loading ...
            </template>
            <Column field="Sort" header="번호" :sortable="true" style="width:40px; text-align:right;" headerClass="tc"/>
            <Column field="DisplayCodeName" header="구분" :sortable="true" v-if="state.defaults.Code != '0'"
                style="width:110px;text-align:center;" headerClass="tc"/>
            <Column field="DisplayTitle" header="제목" :sortable="true" :exportable="true" headerClass="tc"/>
            <Column header="답글" v-if="state.defaults.IsReply === 'Y'" headerStyle="width: 4rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible" headerClass="tc">
                <template #body="{ data }">
                    <a href="javascript:void(0)" v-on:click="methods.moveDtls('R', { data });" title="답글달기">답글</a>
                </template>
            </Column>
            <Column field="CreateDate" header="작성일" :sortable="true" style="width:110px" headerClass="tc"/>
            <Column field="PostingPeriod" header="게시기간" :sortable="true" style="width:210px" headerClass="tc"/>
            <Column field="UseYn" header="사용여부" style="width:110px; text-align:center;" headerClass="tc"/>
            <Column field="Hit" header="조회수" :sortable="true" style="width:110px; text-align:right;" headerClass="tc"/>
        </DataTable>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { notice } from '@/modules/site/PCM_SM0810R.js';
import { common } from '@/modules/common/common.js';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
import router from '@/router'

export default {
    components: {},
    setup() {
        const vrt = useRoute();
        const store = useStore();
        var state = reactive({
            defaults: {},
            parameter: {
                SystCode: notice.systemCode,
                BoardCategory: "",
                Title: "",
                Content: "",
                SearchText: "",        // [check-this] only for ui.
                SearchType: "1",       // [check-this] only for ui. 1 : 제목, 2 : 내용, 3 : 작성자
                UseYn: "Y",
                UserNo: "",            // [check-this] no use in sp.                
                CodeVal: "",
                GroupNo: "0",
                CurPage: "1",
                ListSize: notice.listSize,
                ExcludeCodeVal: null,
                IsContainExpireContens: 'N'
            },
            boardDatas: new Array(),
            codes: []
        });
        const methods = {
            /**
             * # Get Board Data
             */
            getNotices: () => {
                // [check-this] 검색 조건 "제목", "내용", "작성자" 에 따라 SP 파라미터가 달라져야 한다.                
                state.parameter.Title = state.parameter.SearchType === "1" ? state.parameter.SearchText : "";
                state.parameter.Content = state.parameter.SearchType === "2" ? state.parameter.SearchText : "";
                state.parameter.IsComment = state.defaults.IsComment;
                notice.getNotices(state.parameter).then(result => {
                    state.boardDatas = result.notices;
                });
            },
            /**
             * # 신규 : querystring as-is same
             */
            moveDtls: function (arg, item) {
                var q = vrt.query;
                q["Post"] = arg;
                // [check-this] as-is 답글 로직 이상하다.. 확인이 필요해보임.
                if (arg === "R") q.DepthNo = "2", q.ParentNo = item.data.Seq;
                if (sessionStorage.getItem("selectedLeftChildMenuId") == "REC0101") q.boardcategory = "RECCM001";
                router.push({ name: 'PCM_SM0812E', query: q });
            }
        };
        const eventHandler = {
            dataTable: {
                /**
                 * # List Row Clicked
                 * @param event e 
                 */
                onRowClick: (e) => {
                    var q = vrt.query;
                    q["Seq"] = e.data.Seq;
                    if (sessionStorage.getItem("selectedLeftChildMenuId") == "REC0101") q.boardcategory = "RECCM001";
                    router.push({ name: 'PCM_SM0811D', query: q });
                }
            }
        };
        onMounted(() => {
            // [fix-me]
            //store.commit("setLoadingStatus", true);

            let boardcategory = ''

            if (sessionStorage.getItem('selectedTopMenuId') === 'REC' && !!!vrt.query.boardcategory) boardcategory = 'RECCM001'
            else boardcategory = vrt.query.boardcategory

            notice.getBoardInfo(boardcategory).then((res) => {
                if (res) {
                    state.defaults = res, state.parameter["BoardCategory"] = boardcategory;
                    methods.getNotices();
                } else {
                    alertManager.alert.warning("사용할 수 없는 게시판입니다.").then((x) => {
                        if (x) router.push({ name: "Home" });
                    });
                }
            });

            if(boardcategory.includes('RECCM001')){

                common.getSystemCodeInfo("CODEINFO", "CD021000").then(res => {
                    if (res) state.codes = state.codes.concat(res);
                });
            }
            else{

                common.getSystemCodeInfo("CODEINFO", "CD009000").then(res => {
                    if (res) state.codes = state.codes.concat(res);
                });
            }
            
        });

        return { state, notice, methods, eventHandler };
    }
}
</script>